import moment from 'moment';

import i18n from '../context/i18n';

export type FormatToPartResult = {
  type: string;
  value: string;
  unit?: string;
};

export enum DurationType {
  HOUR = 'hour',
  MINUTE = 'minute',
  SECOND = 'second',
  DAY = 'day',
  MONTH = 'month',
  YEAR = 'year',
}

/**
 * Returns the duration type of relative time needed for DURATION_FILTER formatting
 */
export const getDurationUnit = (duration: DurationType) => {
  if (duration.includes(DurationType.YEAR)) {
    return DurationType.YEAR;
  } else if (duration.includes(DurationType.MONTH)) {
    return DurationType.MONTH;
  } else if (duration.includes(DurationType.DAY)) {
    return DurationType.DAY;
  } else if (duration.includes(DurationType.HOUR)) {
    return DurationType.HOUR;
  } else if (duration.includes(DurationType.MINUTE)) {
    return DurationType.MINUTE;
  } else if (duration.includes(DurationType.SECOND)) {
    return DurationType.SECOND;
  } else {
    return DurationType.HOUR;
  }
};

/**
 * Handles the formatting of the formatted relative time in parts
 * @param formattedResult An Array of objects containing the formatted relative time in parts
 * @param originalString The initial value of the string before formatting
 * @returns string of final formatted translation based on language.
 */
export const formatTranslatedDuration = (
  formattedResult: FormatToPartResult[],
  originalString: string
) => {
  const finalTranslation = formattedResult.reduce(
    (total, part: FormatToPartResult) => {
      if (part.type === 'integer' && originalString.includes('30+')) {
        total += '30+';
      } else {
        total += part.value;
      }
      return total;
    },
    ''
  );

  return finalTranslation;
};

/**
 * Calculates the duration from the `date` input to today's date, and returns the translated string.
 *
 * @example getTranslatedDuration({date: "2024-09-06T17:34:18.271Z"}) // "4 days ago"
 * @example getTranslatedDuration({date: "2024-09-06T17:34:18.271Z", locale: "fr-CA"}) // "il y a 4 jours"
 */
export const getTranslatedDuration = ({
  date,
  locale = 'en-US',
}: {
  date: any;
  locale?: string;
}) => {
  const then = new Date(date);
  const now = new Date();
  const minutesElapsed = Math.floor((now.getTime() - then.getTime()) / 60000);
  const durationSince =
    minutesElapsed < 60 * 24
      ? moment(new Date(date)).fromNow()
      : moment.utc(date).from(moment.utc().startOf('day'));

  return i18n.t('work.find.duration', '{{value, DURATION_FILTER}}', {
    value: durationSince,
    lng: locale,
  });
};
