import type { TFunction } from 'react-i18next';

import awsExports from '../aws-exports.json';

import { parseJwt } from '.';

/**
 * Prefix for the local storage values related to Cognito IdP.
 */
export const COGNITO_LOCAL_STORAGE_PREFIX = 'CognitoIdentityServiceProvider';

/**
 * Defines the structure of the identities
 * array in the Cognito id token that contains
 * the list of identity providers associated with the
 * id token.
 */
export type CognitoIdentity = {
  userId?: string;
  providerName?: string; // 'Azure-AD-OIDC'
  providerType?: string; // 'OIDC'
  [key: string]: any;
};

/**
 * Checks if the identity provider of the given id `token` from Cognito
 * is Azure-AD-OIDC. This is so the user can be redirected to the hosted UI
 * instead of our sign-in flow.
 */
export const isTokenFromAzureOIDC = (
  token?: {
    identities?: CognitoIdentity[];
  } | null
) => {
  return token?.identities?.some(
    (identity) =>
      identity.providerType === 'OIDC' &&
      identity.providerName === 'Azure-AD-OIDC'
  );
};

/**
 * Determines if the given `errorMsg` indicates that the session has expired.
 */
export const isExpiredSessionError = (errorMsg: string) => {
  return errorMsg === 'Cannot retrieve a new session. Please authenticate.';
};

/**
 * Retrieves the given Cognito-related `attribute` from local storage.
 * If the given `attribute` is a jwt, then it will also be parsed.
 *
 * WARNING: This bypasses Amplify's refresh/verification logic. That is, there is no guarantee
 * that these values are valid or correct. Use with caution. If you are just looking to access the
 * user details/tokens from Cognito, chances are: you just want `Auth.currentUserSession()` from Amplify.
 */
export const getCognitoAttrFromStorage = (
  attribute:
    | 'LastAuthUser'
    | 'userData'
    | 'refreshToken'
    | 'idToken'
    | 'clockDrift'
    | 'accessToken'
) => {
  const cognitoUserInLocalStorage = localStorage.getItem(
    `${COGNITO_LOCAL_STORAGE_PREFIX}.${awsExports.client_id}.LastAuthUser`
  );

  if (attribute === 'LastAuthUser') return cognitoUserInLocalStorage;

  const retVal = localStorage.getItem(
    `${COGNITO_LOCAL_STORAGE_PREFIX}.${awsExports.client_id}.${cognitoUserInLocalStorage}.${attribute}`
  );

  if (
    retVal &&
    (attribute === 'accessToken' ||
      attribute === 'idToken' ||
      attribute === 'refreshToken')
  ) {
    return parseJwt(retVal);
  }

  return retVal;
};

export type HelperLabel = {
  id: string;
  label: string;
  state: PasswordInputStates;
  getState: (password: string, focusedStated: PasswordInputStates) => PasswordInputStates;
};

export type PasswordInputStates = 'active' | 'invalid' | 'valid';

/**
 * Static for the initial values of password error labels.
 */
export const getInitialPasswordErrorsLabel = (
  t: TFunction<'translation'>
): HelperLabel[] => {
  return [
    {
      id: 'errorLabel1',
      label: t('password.errors_label1', 'One lowercase letter'),
      state: 'active',
      getState: (password, focusedStated) => /[a-z]/.test(password) ? 'valid' : focusedStated,
    },
    {
      id: 'errorLabel2',
      label: t('password.errors_label2', 'One uppercase letter'),
      state: 'active',
      getState: (password, focusedStated) => /[A-Z]/.test(password) ? 'valid' : focusedStated,
    },
    {
      id: 'errorLabel3',
      label: t('password.errors_label3', 'One numeric digit'),
      state: 'active',
      getState: (password, focusedStated) => /\d/.test(password) ? 'valid' : focusedStated,
    },
    {
      id: 'errorLabel4',
      label: t('password.errors_label4', '8 characters minimum'),
      state: 'active',
      getState: (password, focusedStated) => password.length >= 8 ? 'valid' : focusedStated,
    },
    {
      id: 'errorLabel5',
      label: t('password.errors_label5', 'Cannot include blank spaces'),
      state: 'active',
      getState: (password, focusedStated) => password.indexOf(' ') < 1 ? 'valid' : focusedStated,
    },
  ];
};

/**
 * @returns boolean if password is invalid
 */
export const isInvalidPassWord = (passwordErrors: HelperLabel[]) =>
  passwordErrors.some((error) => error.state === 'invalid');

/**
 * @returns boolean if password is valid
 */
export const isValidPassword = (passwordErrors: HelperLabel[]) =>
  passwordErrors.every((error) => error.state === 'valid');

/**
 * gets password input state.
 * @params an array of password errors
 * @returns an error state
 */
export const getPasswordInputState = (passwordErrors: HelperLabel[]) => {
  if (isValidPassword(passwordErrors)) {
    return 'valid';
  } else if (isInvalidPassWord(passwordErrors)) {
    return 'invalid';
  }
  return 'active';
};

/**
 * gets password input state.
 * @params current password input
 * @params a boolean value representing if password is in focus or not
 * @params an array of password errors
 * @returns an error state
 */
export const getPasswordErrors = (
  password: string,
  isFocused: boolean,
  passwordErrors: HelperLabel[]
) => {
  const newPasswordErrors = [...passwordErrors];
  const getFocusedState = isFocused ? 'active' : 'invalid';

  newPasswordErrors.forEach(error => {
    error.state = error.getState(password, getFocusedState);
  })

  return newPasswordErrors;
};
