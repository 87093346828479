import { FC } from 'react';

type SVGWebSearchProps = {
  className?: string;
  width?: string;
  height?: string;
};

export const SvgWebSearch: FC<SVGWebSearchProps> = ({ width = '256', height = '202', ...props }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 256 202"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_220_10453)">
        <path
          d="M232.405 0.131714H0.0992432V148.911H232.405V0.131714Z"
          fill="#E6E6E6"
        />
        <path
          d="M225.762 18.7923H6.74194V140.109H225.762V18.7923Z"
          fill="white"
        />
        <path d="M232.306 0H0V9.8692H232.306V0Z" fill="#C9CBE3" />
        <path
          d="M7.33406 6.87485C8.34429 6.87485 9.16324 6.0559 9.16324 5.04567C9.16324 4.03544 8.34429 3.21649 7.33406 3.21649C6.32383 3.21649 5.50488 4.03544 5.50488 5.04567C5.50488 6.0559 6.32383 6.87485 7.33406 6.87485Z"
          fill="white"
        />
        <path
          d="M14.2771 6.87485C15.2873 6.87485 16.1062 6.0559 16.1062 5.04567C16.1062 4.03544 15.2873 3.21649 14.2771 3.21649C13.2668 3.21649 12.4479 4.03544 12.4479 5.04567C12.4479 6.0559 13.2668 6.87485 14.2771 6.87485Z"
          fill="white"
        />
        <path
          d="M21.2202 6.87485C22.2304 6.87485 23.0494 6.0559 23.0494 5.04567C23.0494 4.03544 22.2304 3.21649 21.2202 3.21649C20.2099 3.21649 19.391 4.03544 19.391 5.04567C19.391 6.0559 20.2099 6.87485 21.2202 6.87485Z"
          fill="white"
        />
        <path
          d="M82.9207 31.3799H26.6428V127.521H82.9207V31.3799Z"
          fill="#E6E6E6"
        />
        <path
          d="M139.534 31.3799H92.6355V57.8439H139.534V31.3799Z"
          fill="#C9CBE3"
        />
        <path
          d="M139.534 65.2136H92.6355V93.6876H139.534V65.2136Z"
          fill="#E6E6E6"
        />
        <path
          d="M139.534 101.057H92.6355V127.521H139.534V101.057Z"
          fill="#E6E6E6"
        />
        <path
          d="M205.861 31.3799H149.583V73.2778H205.861V31.3799Z"
          fill="#E6E6E6"
        />
        <path
          d="M205.861 85.6234H149.583V127.521H205.861V85.6234Z"
          fill="#E6E6E6"
        />
        <path
          d="M184.181 76.2878V67.2677C184.181 64.0621 185.455 60.9878 187.721 58.7211C189.988 56.4543 193.062 55.1809 196.268 55.1809C199.474 55.1809 202.548 56.4543 204.815 58.7211C207.081 60.9878 208.355 64.0621 208.355 67.2677V76.2878C208.354 76.7182 208.183 77.1309 207.879 77.4353C207.574 77.7397 207.162 77.9109 206.731 77.9114H185.805C185.374 77.9109 184.962 77.7397 184.657 77.4353C184.353 77.1309 184.182 76.7182 184.181 76.2878Z"
          fill="#000022"
        />
        <path
          d="M214.14 193.673L209.877 194.853L203.298 178.971L209.59 177.23L214.14 193.673Z"
          fill="#FFB8B8"
        />
        <path
          d="M206.495 194.477L214.717 192.202L216.149 197.379L202.751 201.087C202.563 200.407 202.51 199.697 202.597 198.997C202.683 198.297 202.906 197.62 203.254 197.007C203.602 196.393 204.067 195.854 204.623 195.42C205.179 194.986 205.815 194.666 206.495 194.477Z"
          fill="#000022"
        />
        <path
          d="M169.695 196.952H165.271L163.167 179.891H169.695L169.695 196.952Z"
          fill="#FFB8B8"
        />
        <path
          d="M162.112 195.688H170.642V201.059H156.74C156.74 200.354 156.879 199.656 157.149 199.004C157.419 198.352 157.815 197.76 158.314 197.261C158.812 196.763 159.405 196.367 160.056 196.097C160.708 195.827 161.406 195.688 162.112 195.688Z"
          fill="#000022"
        />
        <path
          d="M164.361 101.601C164.677 102.027 165.082 102.379 165.548 102.631C166.015 102.884 166.531 103.031 167.06 103.063C167.59 103.094 168.12 103.009 168.613 102.814C169.106 102.619 169.55 102.317 169.914 101.932L181.673 107.22L180.603 100.606L169.601 96.5982C168.913 96.0294 168.04 95.733 167.147 95.765C166.255 95.797 165.405 96.1553 164.759 96.7719C164.113 97.3885 163.716 98.2207 163.642 99.1107C163.569 100.001 163.825 100.887 164.361 101.601H164.361Z"
          fill="#FFB8B8"
        />
        <path
          d="M188.792 141.375C185.098 141.375 181.273 140.824 178.059 139.188C176.352 138.336 174.84 137.141 173.616 135.678C172.393 134.215 171.484 132.516 170.948 130.685C169.273 125.382 171.385 120.172 173.427 115.133C174.693 112.011 175.888 109.062 176.198 106.155L176.306 105.123C176.79 100.488 177.207 96.4842 179.516 94.9826C180.714 94.2043 182.323 94.1599 184.438 94.8466L204.298 101.298L203.568 138.998L203.447 139.039C203.343 139.074 196.342 141.375 188.792 141.375Z"
          fill="#000022"
        />
        <path
          d="M184.091 83.1099C184.091 83.1099 193.832 80.2235 201.409 81.3059C201.409 81.3059 197.08 105.119 198.523 113.056C199.966 120.994 173.447 116.123 178.859 108.546L180.663 99.5263C180.663 99.5263 177.055 95.9183 180.302 91.5887L184.091 83.1099Z"
          fill="#C9CBE3"
        />
        <path
          d="M179.299 140.66L167.293 138.409L171.485 106.151C171.767 105.25 178.26 84.8121 181.209 83.7062C183.35 82.9522 185.54 82.3438 187.763 81.8853L188.191 81.7999L185.783 85.411L176.199 108.378L179.299 140.66Z"
          fill="#000022"
        />
        <path
          d="M173.044 192.999L157.531 190.414L166.236 154.866L179.19 106.253L179.318 107.116C179.328 107.18 180.538 113.531 198.495 110.713L198.653 110.689L198.697 110.842L220.4 187.526L202.724 190.472L186.602 134.524L173.044 192.999Z"
          fill="#000022"
        />
        <path
          d="M194.545 146.697L194.554 146.424C194.565 146.121 195.632 115.982 195.276 103.861C194.918 91.699 198.856 81.1665 198.895 81.0615L198.928 80.9769L199.015 80.9519C204.109 79.4966 208.528 83.8403 208.573 83.8843L208.635 83.9463L207.184 95.9164L213.395 139.444L194.545 146.697Z"
          fill="#000022"
        />
        <path
          d="M193.784 77.7014C198.678 77.7014 202.646 73.7339 202.646 68.8397C202.646 63.9456 198.678 59.9781 193.784 59.9781C188.89 59.9781 184.923 63.9456 184.923 68.8397C184.923 73.7339 188.89 77.7014 193.784 77.7014Z"
          fill="#FFB8B8"
        />
        <path
          d="M181.322 67.9893C181.325 65.4544 182.333 63.0241 184.125 61.2317C185.918 59.4392 188.348 58.431 190.883 58.4281H192.687C195.222 58.431 197.652 59.4392 199.445 61.2317C201.237 63.0241 202.245 65.4544 202.248 67.9893V68.1697H198.436L197.135 64.5286L196.875 68.1697H194.905L194.249 66.3326L194.118 68.1697H181.322V67.9893Z"
          fill="#000022"
        />
        <path
          d="M192.912 78.9257C192.733 78.6833 192.626 78.3953 192.604 78.0949C192.581 77.7944 192.644 77.4937 192.785 77.2274C194.697 73.5912 197.374 66.8723 193.821 62.7288L193.565 62.431H203.88V77.9166L194.51 79.5697C194.415 79.5865 194.319 79.595 194.222 79.5951C193.967 79.5951 193.715 79.5344 193.487 79.4181C193.259 79.3017 193.062 79.1329 192.912 78.9257Z"
          fill="#000022"
        />
        <path
          d="M151.385 14.99C144.167 8.89978 135.335 5.03867 125.964 3.8765C116.592 2.71432 107.084 4.30119 98.5976 8.44399C90.1112 12.5868 83.0119 19.1069 78.1635 27.211C73.3151 35.315 70.9268 44.6535 71.289 54.0902C71.6513 63.5269 74.7486 72.6549 80.204 80.3633C85.6595 88.0718 93.2378 94.0285 102.017 97.5085C110.796 100.988 120.397 101.842 129.652 99.9644C138.908 98.0872 147.418 93.5603 154.147 86.9347L213.902 137.356C214.811 138.124 215.988 138.5 217.175 138.401C218.361 138.302 219.46 137.735 220.228 136.826C220.997 135.917 221.373 134.739 221.274 133.553C221.174 132.367 220.608 131.268 219.699 130.5L219.692 130.494L159.937 80.0729C166.878 70.1101 169.849 57.9141 168.267 45.8749C166.686 33.8357 160.665 22.8213 151.385 14.99ZM147.533 75.2176C142.877 80.7346 136.691 84.7473 129.755 86.7482C122.819 88.7491 115.446 88.6484 108.568 86.4588C101.689 84.2692 95.6144 80.0891 91.1116 74.447C86.6088 68.805 83.8802 61.9544 83.2709 54.7616C82.6615 47.5688 84.1987 40.3568 87.6882 34.0377C91.1776 27.7185 96.4625 22.576 102.875 19.2604C109.287 15.9449 116.538 14.6052 123.711 15.4108C130.885 16.2164 137.658 19.131 143.175 23.7862H143.175C146.839 26.8772 149.857 30.6593 152.058 34.9168C154.26 39.1743 155.601 43.8237 156.006 48.5995C156.41 53.3754 155.87 58.1841 154.417 62.7513C152.963 67.3184 150.624 71.5545 147.533 75.2176Z"
          fill="#3F3D56"
        />
        <path
          opacity="0.3"
          d="M96.1012 79.5748C89.0995 73.6663 84.5758 65.3406 83.4285 56.2511C82.2811 47.1616 84.594 37.973 89.9078 30.5099C89.21 31.2127 88.5354 31.9487 87.884 32.7179C84.793 36.3811 82.4539 40.6172 81 45.1843C79.5462 49.7514 79.0062 54.5602 79.4108 59.3361C79.8154 64.1119 81.1567 68.7613 83.3582 73.0188C85.5596 77.2763 88.5781 81.0584 92.2412 84.1494C95.9043 87.2403 100.14 89.5795 104.708 91.0333C109.275 92.4871 114.083 93.0272 118.859 92.6226C123.635 92.218 128.285 90.8766 132.542 88.6752C136.799 86.4738 140.582 83.4553 143.673 79.7922C144.323 79.0219 144.935 78.2331 145.509 77.4257C139.046 83.9193 130.377 87.745 121.224 88.1432C112.071 88.5413 103.103 85.4828 96.1012 79.5748Z"
          fill="black"
        />
        <path
          d="M196.629 117.093C197.145 117.219 197.681 117.23 198.201 117.126C198.722 117.023 199.213 116.806 199.64 116.492C200.067 116.178 200.421 115.774 200.675 115.308C200.929 114.843 201.078 114.327 201.112 113.798L213.535 110.349L208.485 105.946L197.462 109.896C196.569 109.899 195.708 110.229 195.042 110.824C194.376 111.419 193.952 112.238 193.849 113.125C193.746 114.012 193.973 114.906 194.485 115.637C194.998 116.368 195.761 116.886 196.629 117.093Z"
          fill="#FFB8B8"
        />
        <path
          d="M201.793 115.268L201.037 108.085L211.415 102.36L204.649 94.1696L205.753 84.9694L208.502 83.7911L208.587 83.9011C209.884 85.568 221.253 100.238 221.253 102.052C221.253 103.915 219.078 109.385 216.102 110.873C213.232 112.308 202.453 115.097 201.996 115.216L201.793 115.268Z"
          fill="#000022"
        />
        <path
          d="M255.639 201.541H118.174C118.078 201.541 117.987 201.503 117.919 201.436C117.851 201.368 117.813 201.276 117.813 201.18C117.813 201.085 117.851 200.993 117.919 200.925C117.987 200.858 118.078 200.82 118.174 200.82H255.639C255.735 200.82 255.827 200.858 255.894 200.925C255.962 200.993 256 201.085 256 201.18C256 201.276 255.962 201.368 255.894 201.436C255.827 201.503 255.735 201.541 255.639 201.541Z"
          fill="#CCCCCC"
        />
      </g>
      <defs>
        <clipPath id="clip0_220_10453">
          <rect width="256" height="201.541" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
