import { useFeatures } from '../context/FeatureContext';

/**
 * @returns `availableLangsForUser` -  A list of languages that are available for user selection, based on app or tenant-feature configurations.
 */
const useLocales = (): { availableLangsForUser: string[] } => {
  const { isAdditionalLangsSpanishPortugueseEnabled } = useFeatures();

  const availableLangsForUser = ((): string[] => {
    const availableLangsForUser = ['en-US'];
    if (import.meta.env.VITE_FR_ENABLED === 'true') {
      availableLangsForUser.push('fr-CA');
    }
    if (isAdditionalLangsSpanishPortugueseEnabled) {
      availableLangsForUser.push('es-ES', 'pt-PT');
    }

    return availableLangsForUser;
  })();

  return { availableLangsForUser };
};

export default useLocales;
