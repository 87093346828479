import type { TFunction } from 'react-i18next';
import { isMatch } from 'date-fns';
import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

import i18n, { AppSupportedLangs } from '../context/i18n';
import {
  ExternalAssessment,
  IntegrationType,
  NextStepsMetadata,
  Locale,
  GetCurrentUserTenantQuery,
} from '../generated-mesh/graphql';

export type ESFilterItem = {
  key: string;
  doc_count: number;
};

/**
 * All possible states which a next step card could accept.
 *
 * Completed - Indicates that the card has been completed.
 *
 * In progress - Indicates that the card is in progress.
 *
 * Projected - Indicates that the card has not been started
 *
 * Assigned - Indicates that the card has been assigned to the user by a coach.
 */
export enum NextStepCardStatus {
  COMPLETED = 'completed',
  IN_PROGRESS = 'in_progress',
  PROJECTED = 'projected',
  ASSIGNED = 'assigned',
}

export enum NextStepCardLinkType {
  INTERNAL = 'internal',
  EXTERNAL = 'external',
  NO_CTA = 'no_cta',
}

export enum LocaleOptions {
  EN_US = 'en-US',
  FR_CA = 'fr-CA',
}

/**
 * Takes the union of two object arrays with respect to the uniqueKey parameter
 * which is a field in the object T.
 */
export const union = <T extends Record<string, unknown>>(
  arrA: T[],
  arrB: T[],
  uniqueKey: keyof T
): T[] => {
  const elements = [...arrA, ...arrB];

  return [
    ...new Map(
      elements.map((element) => [element[uniqueKey], element])
    ).values(),
  ];
};

/**
 * Takes a value and converts to number
 * @returns NaN if value is null, undefined, '' or Object, Number otherwise.
 */
export const toNumber = <T extends unknown>(value: T) => {
  if ((!value && value !== 0) || value instanceof Object) {
    return NaN;
  }
  return Number(value);
};

/**
 * Sorts filter items in ReactiveSearch Multilist component lexicographically
 */
export const sortESFilters = (data: ESFilterItem[]) => {
  return data.sort((itemA, itemB) => {
    if (itemA.key > itemB.key) {
      return 1;
    } else if (itemA.key < itemB.key) {
      return -1;
    } else {
      return 0;
    }
  });
};

/**
 * Escapes any invalid regex characters in the given string so
 * that it can be safely passed to a RegExp constructor.
 */
const escapeInvalidChars = (str: string) => {
  return str.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1');
};

/**
 * Highlights pattern that matches the given `inputValue` in the `itemLabel`.
 * Used to implement matching keyword highlighting for search inputs
 * that have a dropdown with matching results i.e. Elasticsearch's DataSearch.
 */
export const searchKeywordMatch = (inputValue: string, itemLabel: string) => {
  // First match entire search keyword string
  const trimmedCurrentValue = (inputValue as string).trim();
  const regex = new RegExp(escapeInvalidChars(trimmedCurrentValue), 'ig');

  if (itemLabel.match(regex)) {
    return itemLabel.replace(regex, (matchedWord) => {
      return '<b>' + matchedWord + '</b>';
    });
  }

  // If entire search keyword does not match, try to match each individual word
  const words = itemLabel.split(' ').map((item) => item);
  const wordsInInput = (inputValue as string)
    .split(' ')
    .filter((item) => item !== '')
    .map((item) => item);
  const res: string[] = [];

  words.forEach((word) => {
    let highlightedValue = '';

    wordsInInput.forEach((inputWord) => {
      const inputWordRegex = new RegExp(
        escapeInvalidChars(inputWord.trim()),
        'ig'
      );
      const match = word.match(inputWordRegex);

      // if there is a match with a longer string, highlight that one instead
      if (match && match.length > highlightedValue.length) {
        highlightedValue = match[0];
      }
    });

    if (!highlightedValue) {
      res.push(word);
    } else {
      const highlightedValueRegex = new RegExp(
        escapeInvalidChars(highlightedValue),
        'ig'
      );

      res.push(
        word.replace(highlightedValueRegex, (matchedWord) => {
          return '<b>' + matchedWord + '</b>';
        })
      );
    }
  });

  return res.join(' ');
};

/**
 * Conditionally joins the given classNames.
 */
export const csx = (classNames: (string | boolean)[]): string => {
  const res: string[] = [];

  for (let i = 0; i < classNames.length; ++i) {
    const elem: string | boolean = classNames[i];

    if (typeof elem === 'string') {
      res.push(elem);
    }
  }

  return res.join(' ');
};

/**
 * Indeed API Supported Countries
 * see: https://developer.indeed.com/docs/publisher-jobs/countries
 */
export const supportedCountries = (t: TFunction<'translation'>) => [
  { key: 'ar', value: t('countries.ar') },
  { key: 'au', value: t('countries.au') },
  { key: 'at', value: t('countries.at') },
  { key: 'bh', value: t('countries.bh') },
  { key: 'be', value: t('countries.be') },
  { key: 'br', value: t('countries.br') },
  { key: 'ca', value: t('countries.ca') },
  { key: 'cl', value: t('countries.cl') },
  { key: 'cn', value: t('countries.cn') },
  { key: 'co', value: t('countries.co') },
  { key: 'cz', value: t('countries.cz') },
  { key: 'dk', value: t('countries.dk') },
  { key: 'fi', value: t('countries.fi') },
  { key: 'fr', value: t('countries.fr') },
  { key: 'de', value: t('countries.de') },
  { key: 'gr', value: t('countries.gr') },
  { key: 'hk', value: t('countries.hk') },
  { key: 'hu', value: t('countries.hu') },
  { key: 'in', value: t('countries.in') },
  { key: 'id', value: t('countries.id') },
  { key: 'ie', value: t('countries.ie') },
  { key: 'il', value: t('countries.il') },
  { key: 'it', value: t('countries.it') },
  { key: 'jp', value: t('countries.jp') },
  { key: 'kr', value: t('countries.kr') },
  { key: 'kw', value: t('countries.kw') },
  { key: 'lu', value: t('countries.lu') },
  { key: 'my', value: t('countries.my') },
  { key: 'mx', value: t('countries.mx') },
  { key: 'nl', value: t('countries.nl') },
  { key: 'nz', value: t('countries.nz') },
  { key: 'no', value: t('countries.no') },
  { key: 'om', value: t('countries.om') },
  { key: 'pk', value: t('countries.pk') },
  { key: 'pe', value: t('countries.pe') },
  { key: 'ph', value: t('countries.ph') },
  { key: 'pl', value: t('countries.pl') },
  { key: 'pt', value: t('countries.pt') },
  { key: 'qt', value: t('countries.qt') },
  { key: 'ro', value: t('countries.ro') },
  { key: 'sa', value: t('countries.sa') },
  { key: 'sg', value: t('countries.sg') },
  { key: 'za', value: t('countries.za') },
  { key: 'es', value: t('countries.es') },
  { key: 'se', value: t('countries.se') },
  { key: 'ch', value: t('countries.ch') },
  { key: 'tw', value: t('countries.tw') },
  { key: 'th', value: t('countries.th') },
  { key: 'tr', value: t('countries.tr') },
  { key: 'ae', value: t('countries.ae') },
  { key: 'gb', value: t('countries.gb') },
  { key: 'us', value: t('countries.us') },
  { key: 've', value: t('countries.ve') },
  { key: 'vn', value: t('countries.vn') },
];

export const getLongNameCountry = (
  key: string,
  t: TFunction<'translation'>
) => {
  return supportedCountries(t).find(
    (country) => country.key.toLowerCase() === key.toLowerCase()
  )?.value;
};

export const presignedUrlUpload = async (url: string, data: FormData) => {
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'POST',
      body: data,
    })
      .then(resolve)
      .catch(reject);
  });
};

export const openPdfWindowFromBase64 = (base64PdfString: string) => {
  const base64toBlob = (base64Data: string) => {
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);
    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);
      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: 'application/pdf' });
  };
  const file = base64toBlob(base64PdfString);
  const fileURL = URL.createObjectURL(file);
  const pdfWindow = window.open();
  if (pdfWindow && pdfWindow.location) {
    pdfWindow.location.href = fileURL;
  }
};

/**
 * Returns a new array which contains no duplicates from
 * the given `array`.
 */
export const dedupe = <T>(array: T[]) => {
  // The Set data structure will automatically remove any duplicates
  return Array.from(new Set(array));
};

/**
 * This util retrieves the career interest pref_label from the career-service .
 * Note: Intended for use for tags in ResourceCard.
 */
export const getCareerInterestLabel = (
  career_interests: string[],
  industriesData?: any // GetAllCareerIndustriesQuery
) => {
  if (!industriesData) {
    return career_interests;
  }
  return career_interests
    ?.map((key) =>
      industriesData.careerAreas.find((item: any) => item.machine_key === key)
    )
    .map((item?: { pref_label: string }) => {
      return item?.pref_label || '';
    });
};

/**
 * Obtains the ElasticSearch environment-specific index prefix.
 * This is necessary because our ES indices follow this type of pattern: 'production-learning-store'.
 * Hence, we need to make sure we adhere to the following mapping when resolving the environment name:
 * - 'prod-us', 'prod-ca', 'production' -> 'production-{rest-of-index}';
 * - 'sandbox' -> 'sandbox-{rest-of-index}';
 * - everything else resolves to 'dev00-{rest-of-index}'.
 */
export const getESStoreIndexPrefix = () => {
  const envName = import.meta.env.VITE_ENV || '';

  switch (envName) {
    case 'production':
    case 'prod-us':
    case 'prod-ca':
      return 'production';

    case 'sandbox':
      return 'sandbox';

    default:
      return 'dev00';
  }
};

export const getSSOLink = (
  tenantData: GetCurrentUserTenantQuery | undefined,
  url: string | undefined
): string => {
  if (tenantData?.getCurrentUserTenant?.lil_sso_link_format) {
    const redirectUrl = url ? url : '';
    return tenantData.getCurrentUserTenant.lil_sso_link_format.replace(
      `{0}`,
      redirectUrl
    );
  }
  return url ? url : '';
};

export const getESStoreIndex = (name: string) =>
  `${getESStoreIndexPrefix()}-${name}`;

/**
 * This creates an exact copy of an object or array.
 */
export function clone<T>(obj: T): T {
  if (!obj || typeof obj !== 'object') {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map((a) => clone(a)) as any;
  }

  const cloneObj: any = {};

  for (const attribute in obj) {
    if (typeof obj[attribute] === 'object') {
      cloneObj[attribute] = clone(obj[attribute]);
    } else {
      cloneObj[attribute] = obj[attribute];
    }
  }

  return cloneObj;
}

/**
 * Checks whether the given `name` is valid
 * i.e. does not contains invalid/null characters and is not empty.
 */
export const isNameValid = (name?: string | null) => {
  return (
    !!name &&
    !name.includes('undefined') &&
    !name.includes('null') &&
    name !== '' &&
    name !== ' '
  );
};

/**
 * Returns full name of the user or a common "unknown" label.
 * @param firstName - User's first name
 * @param lastName - User's last name
 * @returns String
 */
export const getUserFullName = (firstName?: string, lastName?: string) => {
  const fullName = `${firstName} ${lastName}`.trim();
  return fullName === 'null null'
    ? i18n.t('terms.unknown', '(Unknown)')
    : fullName;
};

/**
 * @description Because the older next steps still have the form version path, here we remove that part of the path
 * @example removeFormVersionPathPartFromLink('/form/8b71774a-235b-4dc4-9f66-2ba6a8a503d5/version/8b71774a-235b-4dc4-9f66-2ba6a8a503d5');
            //returns '/form/8b71774a-235b-4dc4-9f66-2ba6a8a503d5'
 */
export function removeFormVersionPathPartFromLink(link: string) {
  return link.replace(
    /\/version\/[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}/i,
    ''
  );
}

/**
 * @description Check if a next step link is a form
 * @example isFormLink('/form/8b71774a-235b-4dc4-9f66-2ba6a8a503d5'); //returns true
            isFormLink('/form/bad-uuid-format'); //returns false
            isFormLink('some other link'); //returns false
 */
export function isFormLink(link: string) {
  const linkToTest = removeFormVersionPathPartFromLink(link);

  const pattern =
    /^\/form\/[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

  return pattern.test(linkToTest);
}

/**
 * @description Check if a next step link is an assessment
 * @example isExternalAssessmentLink('/external-assessment/VERVOE/slug'); //returns true
            isFormLink('/external-assessment/unsupported-integration/whatever'); //returns false
            isFormLink('some other link'); //returns false
 */
export function isExternalAssessmentLink(uri: string): boolean {
  const patterns = Object.values(IntegrationType).map((type) => {
    return new RegExp(`\\/external\\-assessment\\/${type}\\/\\w+`);
  });

  return patterns.some((pattern) => pattern.test(uri));
}

/**
 * Fetches integration type and external id from external assessment links
 * @param uri of type `/external-assessment/<integrationType>/<externalId>`
 * @returns <externalId, integrationType>
 */
export function getAssessmentDataFromNextStepUri(
  uri: string
): Pick<ExternalAssessment, 'externalId' | 'integrationType'> | null {
  if (!isExternalAssessmentLink(uri)) {
    return null;
  }

  const parts = uri.split(`/`);

  return {
    integrationType: parts[2],
    externalId: parts[3],
  };
}

/**
 * Get (actual) link for a next step, in case of vervoe assessments, for instance,
 * @param nextStepMetadata
 * @param user_uuid
 * @returns
 */
export function getNextStepUri(
  nextStepMetadata: Pick<NextStepsMetadata, 'next_step_uuid' | 'link'>
): string | undefined {
  const { link, next_step_uuid } = nextStepMetadata;

  if (link?.uri && isFormLink(link.uri)) {
    return `${removeFormVersionPathPartFromLink(link.uri)}/${next_step_uuid}`;
  }

  return link?.uri;
}

export const US_ZIPCODE_REGEX = /^\d{5}(-\d{4})?$/;
export const CAN_ZIPCODE_REGEX = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
export const UK_ZIPCODE_REGEX =
  /^([Gg][Ii][Rr]\s*0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z]))))\s*[0-9][A-Za-z]{2})$/;
export const ES_ZIPCODE_REGEX = /^\d{5}$/;
export const PT_ZIPCODE_REGEX = /^\d{4}-\d{3}$/;

/**
 * Determines if the given `zipCode` is a valid UK, US, or Canadian
 * zip/postal code.
 */
export const isValidZipCode = (zipCode: string) => {
  return (
    US_ZIPCODE_REGEX.test(zipCode) ||
    CAN_ZIPCODE_REGEX.test(zipCode) ||
    UK_ZIPCODE_REGEX.test(zipCode) ||
    ES_ZIPCODE_REGEX.test(zipCode) ||
    PT_ZIPCODE_REGEX.test(zipCode)
  );
};

export const isValidDateOfBirth = (dob: string) => {
  const nonEnglishLanguages = ['fr-CA', 'es-ES', 'pt-PT'];

  if (dob && i18n.language === 'en-US' && !isMatch(dob, 'MM/dd/yyyy')) {
    return false;
  } else if (
    dob &&
    nonEnglishLanguages.includes(i18n.language) &&
    !isMatch(dob, 'dd/MM/yyyy')
  ) {
    return false;
  }
  return true;
};

/**
 * Parses the given jwt.
 */
export const parseJwt = (token: string) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
};

/**
 * Convert i18n language to GraphQl Locale Enum
 */
export const i18nLanguageToGraphQlLocate: (
  language: AppSupportedLangs
) => Locale = (language) => {
  switch (language) {
    case 'en-US':
      return Locale.EN_US;
    case 'fr-CA':
      return Locale.FR_CA;
    case 'es-ES':
      return Locale.ES_ES;
    case 'pt-PT':
      return Locale.PT_PT;

    default:
      return Locale.EN_US;
  }
};

/**
 * Checks if a link is valid 
 * @example isValidUrl('abc.123'); //returns false
            isValidUrl('google.com'); //returns false
            isValidUrl('https://google.com') // returns true
 */
export const isValidUrl = (url: string) => {
  try {
    return Boolean(new URL(url));
  } catch (e) {
    return false;
  }
};

/**
 * Recursive definition of the partial utility
 */
export type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends object ? DeepPartial<T[P]> : T[P];
};

/**
 *  Minimum 8 characters, at least 1 uppercase letter, 1 lowercase letter, 1 numeric digit
 *  Allow cognito special chars. See for valid chars:
 *    https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-policies.html
 */
export const VALID_COGNITO_PASSWORD_REGEX =
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d*^$*.[\]{}()?\-"!@#%&/,><':;|_~=+`]{8,}$/;

/**
 * Convert string (usually query params) back to its original type
 * @param query_param
 * @returns query string's supposed type
 * @example parseQueryParam("true") // true
 *          parseQueryParam("null") // null
 *          parseQueryParam("undefined") // undefined
 *          parseQueryParam("abc") // "abc"
 *          parsedQueryParam(null) // null
 *          parsedQueryParam(undefined) // undefined
 */
export const parseQueryParam = (query_param?: string) => {
  switch (query_param) {
    case 'true':
    case 'false':
    case 'null':
      return JSON.parse(query_param);
    case 'undefined':
      return undefined;
    case null:
    case undefined:
    default:
      return query_param;
  }
};

/**
 * Will sanitize and email from URL encode (replace spaces and trim the string).
 * @param emailParamValue
 * @returns the sanitized email
 */
export const sanitizeEmailFromUrl = (emailParamValue: string) =>
  (emailParamValue ?? '').trim().replaceAll(' ', '+').replaceAll(' ', '');

export const sanitizeEmailFromInput = (emailInputValue: string) =>
  (emailInputValue ?? '').trim().replaceAll(' ', '');

/**
 *
 * @param url
 * @returns the url with parentheses removed to prevent disruption in I.e. photo uploads
 */
export const escapeParentheses = (url: string | undefined) => {
  return url ? url.replace(/\(/g, '\\(').replace(/\)/g, '\\)') : '';
};

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

/**
 * Ensures that a URL is absolute by prepending "https://" if no protocol is present.
 *
 * @param {string} url - The URL to ensure is absolute.
 * @returns {string} - The absolute URL.
 *
 * @example
 * // Returns "https://www.google.ca"
 * ensureAbsoluteUrl("www.google.ca");
 *
 * @example
 * // Returns "http://example.com"
 * ensureAbsoluteUrl("http://example.com");
 */
export const ensureAbsoluteUrl = (url: string) => {
  if (url.startsWith('http://') || url.startsWith('https://')) {
    return url;
  }
  return `https://${url}`;
};
