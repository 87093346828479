export const SvgNoJobsWorkRec = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="316"
    height="292"
    viewBox="0 0 316 292"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_3587_46152)">
      <path
        d="M304.323 243.461H11.6772C5.41861 243.461 0.32666 238.37 0.32666 232.111V196.889H315.673V232.111C315.673 238.37 310.582 243.461 304.323 243.461Z"
        fill="#E6E6E6"
      />
      <path
        d="M195.875 237.568H121.247V290.874H195.875V237.568Z"
        fill="#E6E6E6"
      />
      <path
        d="M200.364 284.141H116.758V290.874H200.364V284.141Z"
        fill="#E6E6E6"
      />
      <path
        d="M315.393 211.757H0.32666V32.6956C0.32666 28.4006 3.82103 24.9062 8.11596 24.9062H307.604C311.898 24.9062 315.393 28.4006 315.393 32.6956V211.757Z"
        fill="#3F3D56"
      />
      <path
        d="M307.817 33.043H7.62109V201.939H307.817V33.043Z"
        fill="#F8F7FA"
      />
    </g>
    <g filter="url(#filter0_d_3587_46152)">
      <rect
        x="82.2134"
        y="59.8047"
        width="207.097"
        height="49.6042"
        rx="4"
        fill="white"
      />
    </g>
    <rect x="133.458" y="77.3398" width="83" height="4" rx="2" fill="#EFEFF7" />
    <rect
      x="133.458"
      y="87.3398"
      width="137.885"
      height="4"
      rx="2"
      fill="#EFEFF7"
    />
    <rect
      x="92.1338"
      y="68.4863"
      width="32.2427"
      height="32.2427"
      rx="4"
      fill="url(#paint0_linear_3587_46152)"
    />
    <path
      d="M99.3454 85.7086H117.471V91.0099C117.471 91.2887 117.245 91.5148 116.966 91.5148H99.7493C99.5262 91.5148 99.3454 91.3339 99.3454 91.1108V85.7086Z"
      fill="#27314A"
    />
    <path
      d="M110.352 79.5995V79.7762H111.488V79.2965H110.655C110.488 79.2965 110.352 79.4322 110.352 79.5995Z"
      fill="#F3F1F4"
    />
    <path
      d="M104.849 79.5995V79.7762H105.985V79.2965H105.152C104.984 79.2965 104.849 79.4322 104.849 79.5995Z"
      fill="#F3F1F4"
    />
    <path
      d="M106.048 79.2886L105.985 79.2965V79.8014L106.389 79.7509V79.5892C106.389 79.407 106.229 79.266 106.048 79.2886Z"
      fill="#B7BCB8"
    />
    <path
      d="M111.551 79.2886L111.488 79.2965V79.8014L111.892 79.7509V79.5892C111.892 79.407 111.732 79.266 111.551 79.2886Z"
      fill="#B7BCB8"
    />
    <path
      d="M116.563 89.9548V85.86H117.471V91.0099C117.471 91.2887 117.245 91.5148 116.966 91.5148H99.7493C99.5262 91.5148 99.3454 91.3339 99.3454 91.1108V90.3587H116.159C116.382 90.3587 116.563 90.1778 116.563 89.9548Z"
      fill="#161D27"
    />
    <path
      d="M99.0425 79.7509H117.168V85.7086H99.0425V79.7509Z"
      fill="#33415E"
    />
    <path
      d="M117.723 85.6076V79.7509H117.168V85.7338L117.723 85.6076Z"
      fill="#26324A"
    />
    <path
      d="M110.68 77.5799V79.2965H111.488L111.589 79.2713V77.7566C111.589 77.0874 111.046 76.5449 110.377 76.5449H106.389C105.691 76.5449 105.126 77.11 105.126 77.8071V79.2965H105.985L106.136 79.2713V77.5799H110.68Z"
      fill="#273248"
    />
    <path
      d="M99.4464 85.7086H117.471V86.163H99.9008C99.6498 86.163 99.4464 85.9595 99.4464 85.7086Z"
      fill="#161D27"
    />
    <path
      d="M107.726 79.7509H108.989V84.7493H107.726V79.7509Z"
      fill="#27314A"
    />
    <path
      d="M107.525 84.6988H109.191V86.8193C109.191 86.9866 109.055 87.1223 108.888 87.1223H107.827C107.66 87.1223 107.525 86.9866 107.525 86.8193V84.6988Z"
      fill="#F1F3F2"
    />
    <path
      d="M108.736 86.3144C108.736 86.5096 108.578 86.6679 108.383 86.6679C108.188 86.6679 108.029 86.5096 108.029 86.3144C108.029 86.1193 108.188 85.961 108.383 85.961C108.578 85.961 108.736 86.1193 108.736 86.3144Z"
      fill="#FFBD4C"
    />
    <g filter="url(#filter1_d_3587_46152)">
      <rect
        x="94.2202"
        y="8"
        width="184.068"
        height="44.0881"
        rx="4"
        fill="white"
      />
    </g>
    <rect
      x="103.038"
      y="15.7148"
      width="28.6573"
      height="28.6573"
      rx="4"
      fill="url(#paint1_linear_3587_46152)"
    />
    <path
      d="M109.448 31.0236H125.558V35.7353C125.558 35.9832 125.357 36.1841 125.109 36.1841H109.807C109.609 36.1841 109.448 36.0233 109.448 35.8251V31.0236Z"
      fill="#27314A"
    />
    <path
      d="M119.231 25.5938V25.7508H120.241V25.3245H119.5C119.352 25.3245 119.231 25.4451 119.231 25.5938Z"
      fill="#F3F1F4"
    />
    <path
      d="M114.34 25.5938V25.7508H115.349V25.3245H114.609C114.46 25.3245 114.34 25.4451 114.34 25.5938Z"
      fill="#F3F1F4"
    />
    <path
      d="M115.406 25.3175L115.349 25.3245V25.7733L115.708 25.7284V25.5847C115.708 25.4227 115.566 25.2974 115.406 25.3175Z"
      fill="#B7BCB8"
    />
    <path
      d="M120.297 25.3175L120.241 25.3245V25.7733L120.6 25.7284V25.5847C120.6 25.4227 120.458 25.2974 120.297 25.3175Z"
      fill="#B7BCB8"
    />
    <path
      d="M124.752 34.7976V31.1582H125.558V35.7353C125.558 35.9832 125.357 36.1841 125.109 36.1841H109.807C109.609 36.1841 109.448 36.0233 109.448 35.8251V35.1566H124.393C124.591 35.1566 124.752 34.9958 124.752 34.7976Z"
      fill="#161D27"
    />
    <path
      d="M109.179 25.7284H125.289V31.0236H109.179V25.7284Z"
      fill="#33415E"
    />
    <path
      d="M125.783 30.9338V25.7284H125.289V31.046L125.783 30.9338Z"
      fill="#26324A"
    />
    <path
      d="M119.523 23.7988V25.3245H120.241L120.33 25.3021V23.9559C120.33 23.3611 119.848 22.8789 119.253 22.8789H115.708C115.089 22.8789 114.587 23.3812 114.587 24.0008V25.3245H115.349L115.484 25.3021V23.7988H119.523Z"
      fill="#273248"
    />
    <path
      d="M109.538 31.0236H125.558V31.4274H109.942C109.719 31.4274 109.538 31.2466 109.538 31.0236Z"
      fill="#161D27"
    />
    <path
      d="M116.898 25.7284H118.019V30.1709H116.898V25.7284Z"
      fill="#27314A"
    />
    <path
      d="M116.718 30.1261H118.199V32.0108C118.199 32.1595 118.078 32.28 117.93 32.28H116.987C116.839 32.28 116.718 32.1595 116.718 32.0108V30.1261Z"
      fill="#F1F3F2"
    />
    <path
      d="M117.795 31.562C117.795 31.7355 117.654 31.8762 117.481 31.8762C117.307 31.8762 117.167 31.7355 117.167 31.562C117.167 31.3886 117.307 31.2479 117.481 31.2479C117.654 31.2479 117.795 31.3886 117.795 31.562Z"
      fill="#FFBD4C"
    />
    <rect x="139.41" y="23.043" width="82" height="4" rx="2" fill="#EFEFF7" />
    <rect
      x="139.41"
      y="33.043"
      width="126.753"
      height="4"
      rx="2"
      fill="#EFEFF7"
    />
    <g filter="url(#filter2_d_3587_46152)">
      <rect
        x="94.2202"
        y="117.119"
        width="184.068"
        height="44.0881"
        rx="4"
        fill="white"
      />
    </g>
    <rect x="139.41" y="132.402" width="82" height="4" rx="2" fill="#EFEFF7" />
    <rect
      x="139.41"
      y="142.402"
      width="126.753"
      height="4"
      rx="2"
      fill="#EFEFF7"
    />
    <rect
      x="103.038"
      y="124.836"
      width="28.6573"
      height="28.6573"
      rx="4"
      fill="url(#paint2_linear_3587_46152)"
    />
    <path
      d="M109.448 140.143H125.558V144.854C125.558 145.102 125.357 145.303 125.109 145.303H109.807C109.609 145.303 109.448 145.142 109.448 144.944V140.143Z"
      fill="#27314A"
    />
    <path
      d="M119.231 134.713V134.87H120.241V134.444H119.5C119.352 134.444 119.231 134.564 119.231 134.713Z"
      fill="#F3F1F4"
    />
    <path
      d="M114.34 134.713V134.87H115.349V134.444H114.609C114.46 134.444 114.34 134.564 114.34 134.713Z"
      fill="#F3F1F4"
    />
    <path
      d="M115.406 134.437L115.349 134.444V134.892L115.708 134.848V134.704C115.708 134.542 115.566 134.417 115.406 134.437Z"
      fill="#B7BCB8"
    />
    <path
      d="M120.297 134.437L120.241 134.444V134.892L120.6 134.848V134.704C120.6 134.542 120.458 134.417 120.297 134.437Z"
      fill="#B7BCB8"
    />
    <path
      d="M124.752 143.917V140.277H125.558V144.854C125.558 145.102 125.357 145.303 125.109 145.303H109.807C109.609 145.303 109.448 145.142 109.448 144.944V144.276H124.393C124.591 144.276 124.752 144.115 124.752 143.917Z"
      fill="#161D27"
    />
    <path
      d="M109.179 134.848H125.289V140.143H109.179V134.848Z"
      fill="#33415E"
    />
    <path
      d="M125.783 140.053V134.848H125.289V140.165L125.783 140.053Z"
      fill="#26324A"
    />
    <path
      d="M119.523 132.918V134.444H120.241L120.33 134.421V133.075C120.33 132.48 119.848 131.998 119.253 131.998H115.708C115.089 131.998 114.587 132.5 114.587 133.12V134.444H115.349L115.484 134.421V132.918H119.523Z"
      fill="#273248"
    />
    <path
      d="M109.538 140.143H125.558V140.547H109.942C109.719 140.547 109.538 140.366 109.538 140.143Z"
      fill="#161D27"
    />
    <path d="M116.898 134.848H118.019V139.29H116.898V134.848Z" fill="#27314A" />
    <path
      d="M116.718 139.245H118.199V141.13C118.199 141.279 118.078 141.399 117.93 141.399H116.987C116.839 141.399 116.718 141.279 116.718 141.13V139.245Z"
      fill="#F1F3F2"
    />
    <path
      d="M117.795 140.681C117.795 140.855 117.654 140.995 117.481 140.995C117.307 140.995 117.167 140.855 117.167 140.681C117.167 140.508 117.307 140.367 117.481 140.367C117.654 140.367 117.795 140.508 117.795 140.681Z"
      fill="#FFBD4C"
    />
    <path
      d="M64.8839 106.19C65.814 103.45 67.5202 101.04 69.7951 99.2524C72.07 97.4648 74.8153 96.377 77.6971 96.1214C80.579 95.8657 83.473 96.4531 86.027 97.8122C88.5811 99.1713 90.685 101.243 92.0829 103.776C93.4807 106.31 94.1122 109.194 93.9004 112.08C93.6887 114.965 92.6428 117.727 90.8902 120.028C89.1375 122.33 86.7536 124.073 84.0285 125.045C81.3034 126.016 78.3546 126.175 75.541 125.501L67.8408 148.183C67.7231 148.527 67.4736 148.811 67.147 148.972C66.8204 149.133 66.4434 149.158 66.0985 149.041C65.7537 148.924 65.4691 148.676 65.3074 148.349C65.1456 148.023 65.1197 147.646 65.2354 147.301L65.2363 147.299L72.9365 124.617C69.5392 123.102 66.8303 120.372 65.3408 116.963C63.8512 113.554 63.6883 109.712 64.8839 106.19ZM75.4226 121.381C77.5167 122.092 79.7746 122.166 81.9107 121.594C84.0468 121.021 85.9653 119.829 87.4235 118.166C88.8817 116.503 89.8141 114.446 90.1028 112.253C90.3916 110.061 90.0237 107.832 89.0456 105.848C88.0676 103.865 86.5234 102.216 84.6082 101.11C82.693 100.004 80.493 99.4911 78.2862 99.6357C76.0795 99.7802 73.9652 100.576 72.2106 101.922C70.4561 103.268 69.1402 105.105 68.4293 107.199C67.4759 110.007 67.6771 113.079 68.9886 115.738C70.3002 118.398 72.6145 120.428 75.4226 121.381Z"
      fill="#3F3D56"
    />
    <path
      opacity="0.3"
      d="M89.6045 114.387C90.5067 111.729 90.3764 108.829 89.2394 106.263C88.1025 103.697 86.042 101.652 83.4673 100.535C83.7615 100.609 84.055 100.695 84.3476 100.794C85.738 101.266 87.0218 102.007 88.1258 102.975C89.2297 103.944 90.1321 105.12 90.7815 106.437C91.4309 107.754 91.8145 109.186 91.9105 110.651C92.0065 112.116 91.813 113.586 91.3409 114.976C90.8689 116.367 90.1276 117.651 89.1594 118.754C88.1912 119.858 87.015 120.761 85.6981 121.41C84.3811 122.06 82.9491 122.443 81.4839 122.539C80.0187 122.635 78.5489 122.442 77.1585 121.97C76.8661 121.87 76.5808 121.76 76.3024 121.639C79.0252 122.32 81.9047 121.953 84.3689 120.609C86.8332 119.266 88.7021 117.045 89.6045 114.387Z"
      fill="black"
    />
    <path
      d="M67.6362 134.44C70.2891 133.082 73.1404 133.349 74.0048 135.038C74.8691 136.726 73.4191 139.196 70.7651 140.555C69.7123 141.112 68.5415 141.409 67.3504 141.421L56.0373 147.035L53.5158 141.67L64.9362 136.705C65.6424 135.745 66.5683 134.969 67.6362 134.44Z"
      fill="#FFB6B6"
    />
    <path
      d="M8.90227 151.58C8.89729 151.23 8.90057 148.174 11.1628 146.243C13.7614 144.024 17.4283 144.922 18.7144 145.237C21.6853 145.965 21.929 147.239 24.719 148.549C29.9388 150.999 23.3986 149.745 26.5045 148.959C27.5797 148.687 43.0467 147.615 46.8967 145.245C54.3845 140.636 59.4865 137.842 59.4865 137.842L62.8217 144.512C62.8217 144.512 57.9989 151.218 51.2113 156.186C44.2487 161.282 40.7674 163.83 35.4768 164.814C35.0212 164.899 23.8161 166.812 14.7913 160.07C12.7327 158.532 8.96105 155.714 8.90227 151.58Z"
      fill="#B5CCEC"
    />
    <path
      d="M19.2485 134.618C25.6653 134.618 30.8672 129.416 30.8672 123C30.8672 116.583 25.6653 111.381 19.2485 111.381C12.8317 111.381 7.62988 116.583 7.62988 123C7.62988 129.416 12.8317 134.618 19.2485 134.618Z"
      fill="#FFB6B6"
    />
    <path
      d="M110.241 201.055L98.2759 188.807L92.0991 195.25L101.014 206.994L109.857 208.005L110.241 201.055Z"
      fill="#FFB6B6"
    />
    <path
      d="M41.9169 178.087C42.6168 178.145 44.8408 178.591 46.9666 177.421C48.4174 176.623 49.1614 175.436 49.4127 175.059C52.5304 170.377 64.4968 163.075 75.2363 162.626C77.6684 162.524 79.2692 162.806 80.7242 163.564C82.7874 164.639 83.6111 166.18 86.2293 169.789C87.6614 171.763 89.246 173.623 90.6954 175.584C99.1809 187.067 102.398 187.969 102.069 191.65C101.704 195.735 97.299 199.61 94.3711 199.163C93.1721 198.979 92.7077 198.146 91.1057 196.458C86.4086 191.509 84.4652 192.423 81.121 188.633C77.2166 184.207 77.7277 180.538 75.6907 180.396C73.8017 180.264 72.9989 183.395 68.966 187.553C67.1206 189.456 65.8065 190.329 61.803 193.106C46.2127 203.922 45.9638 204.682 42.8175 205.545C41.441 205.922 31.6707 208.601 28.1903 204.338C22.7438 197.668 35.6107 177.563 41.9169 178.087Z"
      fill="#2F2E41"
    />
    <path
      d="M106.583 203.822C107.463 203.427 108.492 202.965 108.823 201.902C109.047 201.183 108.918 200.297 108.455 199.684C107.921 198.978 107.13 198.884 107.162 198.759C107.211 198.569 109.081 198.643 111.133 199.369C111.521 199.506 113.275 200.147 115.508 201.89C117.296 203.286 117.055 203.566 118.177 204.371C121.62 206.842 124.848 204.895 126.711 207.212C127.43 208.107 127.737 209.377 127.434 210.403C126.827 212.465 123.872 213.175 122.257 213.541C118.368 214.424 115.424 213.703 112.351 213.186C101.897 211.424 96.0243 213.028 95.2382 210.607C95.0894 210.148 95.3311 210.187 96.1371 206.824C96.9326 203.504 96.9065 202.592 97.584 202.327C98.6038 201.926 99.2886 203.747 101.858 204.37C104.134 204.922 106.013 204.078 106.583 203.822Z"
      fill="#2F2E41"
    />
    <path
      d="M88.9684 241.125L85.9453 224.271L77.2163 226.134L77.9983 240.858L84.7472 246.66L88.9684 241.125Z"
      fill="#FFB6B6"
    />
    <path
      d="M45.3312 183.755C45.8777 184.196 47.4678 185.813 49.8835 186.039C51.5322 186.194 52.8143 185.629 53.2341 185.458C58.4432 183.334 72.445 184.012 81.5838 189.671C83.6533 190.953 84.8197 192.085 85.5978 193.529C86.701 195.578 86.5175 197.315 86.6574 201.771C86.7339 204.209 87.0008 206.638 87.0987 209.075C87.6718 223.341 89.8274 225.893 87.488 228.755C84.8925 231.93 79.0714 232.663 76.8999 230.649C76.0106 229.824 76.0946 228.873 75.7168 226.577C74.6091 219.844 72.4877 219.51 71.8491 214.495C71.1035 208.641 73.587 205.891 71.9811 204.63C70.4918 203.461 68.0697 205.6 62.3975 206.777C59.802 207.315 58.2245 207.299 53.3522 207.349C34.3785 207.544 33.7458 208.033 30.658 206.98C29.307 206.52 19.7185 203.25 19.2322 197.768C18.4713 189.19 40.4073 179.78 45.3312 183.755Z"
      fill="#2F2E41"
    />
    <path
      d="M84.3875 241.362C85.3376 241.529 86.4484 241.725 87.319 241.031C87.9081 240.562 88.2992 239.756 88.26 238.989C88.2149 238.104 87.6128 237.583 87.7095 237.498C87.8568 237.368 89.3622 238.479 90.6533 240.232C90.8969 240.563 91.9889 242.079 92.8573 244.775C93.5532 246.934 93.1968 247.031 93.6727 248.327C95.1336 252.305 98.899 252.506 99.139 255.47C99.2316 256.614 98.7722 257.837 97.9452 258.516C96.2846 259.881 93.4413 258.809 91.8993 258.206C88.1859 256.752 86.1546 254.503 83.9021 252.348C76.2417 245.021 70.481 243.051 71.1905 240.605C71.3247 240.142 71.5028 240.31 74.0584 237.98C76.5808 235.68 77.0712 234.911 77.781 235.071C78.8496 235.313 78.3941 237.203 80.17 239.161C81.7438 240.897 83.7719 241.254 84.3875 241.362Z"
      fill="#2F2E41"
    />
    <path
      d="M20.8324 125.219C21.2847 123.601 20.7166 123.011 21.2957 122.285C21.2957 122.285 21.5287 121.993 23.6119 121.204C22.9973 117.638 23.4575 117.035 23.4575 117.035C23.9879 116.34 25.0504 116.172 25.7738 116.263C27.3036 116.455 27.5744 117.838 28.5533 117.807C29.9055 117.764 31.5273 115.057 31.0239 112.711C30.6133 110.797 28.8427 109.376 28.3989 109.623C27.9588 109.867 26.3746 108.789 25.7738 109.005C25.3635 109.153 23.4322 108.956 22.9943 109.16C22.516 109.382 21.1465 110.763 21.0155 110.589C20.1056 109.379 20.125 108.957 19.5971 108.696C18.6616 108.234 17.6446 109.088 15.8911 109.932C12.283 111.667 11.2265 110.632 9.55995 112.094C8.71808 112.832 8.26664 114.243 7.39811 117.035C6.18347 120.939 5.57616 122.892 6.16277 124.756C6.761 126.657 7.64197 126.243 8.94228 128.925C10.3897 131.911 9.89085 133.646 11.2585 134.33C12.3156 134.858 14.0143 134.522 14.6557 133.558C15.7538 131.907 12.9155 129.674 13.8836 127.072C14.4779 125.475 16.311 124.264 17.4352 124.601C18.7063 124.983 18.8 127.262 19.5971 127.226C20.2797 127.196 20.7536 125.501 20.8324 125.219Z"
      fill="#2F2E41"
    />
    <path
      d="M12.0306 136.338C14.4082 133.672 24.0927 136.431 25.0017 140.662C25.3755 142.402 23.9953 143.136 24.384 145.14C24.9525 148.07 28.1714 147.87 30.5607 150.853C33.9527 155.088 30.4582 159.228 34.2667 166.295C35.0805 167.805 35.7664 169.078 37.2006 170.31C40.4917 173.136 43.5545 172.167 45.2303 174.788C46.4835 176.748 45.9686 179.163 45.848 179.729C45.3534 182.049 44.013 182.615 43.0685 184.516C41.497 187.679 43.2763 190.002 43.3773 193.781C43.5037 198.51 40.9908 205.065 35.9653 207.215C30.3193 209.632 23.5098 205.63 20.2147 201.656C18.2485 199.285 17.6777 197.098 16.8175 194.399C12.9731 182.334 10.8013 183.425 7.86134 173.861C4.50047 162.928 3.83977 150.119 7.86134 146.066C8.39438 145.529 9.86132 144.287 10.7953 142.051C12.052 139.043 10.9663 137.531 12.0306 136.338Z"
      fill="#B5CCEC"
    />
    <path
      d="M47.1621 198.396C49.0055 200.738 49.2912 203.587 47.8005 204.76C46.3097 205.933 43.6075 204.985 41.7637 202.642C41.0144 201.716 40.4977 200.624 40.2569 199.457L32.5718 189.435L36.5428 186.406L44.4205 196.181C45.4981 196.69 46.4382 197.449 47.1621 198.396Z"
      fill="#FFB6B6"
    />
    <path
      d="M19.0165 146.499C19.3584 146.423 22.3505 145.802 24.7028 147.623C27.405 149.714 27.2741 153.487 27.2282 154.81C27.1221 157.867 25.9242 158.366 25.2115 161.364C23.8782 166.974 26.4174 172.188 27.8202 175.068C28.3058 176.065 29.8658 179.116 32.9712 182.401C39.0107 188.791 44.3995 192.154 44.3995 192.154L38.6104 197.299C38.6104 197.299 29.389 194.489 23.1407 188.858C16.7313 183.081 6.87475 162.644 4.83147 157.666C4.65551 157.237 7.14804 164.207 11.9072 153.996C12.9928 151.667 14.9817 147.4 19.0165 146.499Z"
      fill="#B5CCEC"
    />
    <defs>
      <filter
        id="filter0_d_3587_46152"
        x="72.2134"
        y="51.8047"
        width="227.098"
        height="69.6035"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.27451 0 0 0 0 0.290196 0 0 0 0 0.372549 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_3587_46152"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_3587_46152"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_3587_46152"
        x="84.2202"
        y="0"
        width="204.068"
        height="64.0879"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.27451 0 0 0 0 0.290196 0 0 0 0 0.372549 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_3587_46152"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_3587_46152"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_d_3587_46152"
        x="84.2202"
        y="109.119"
        width="204.068"
        height="64.0879"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.27451 0 0 0 0 0.290196 0 0 0 0 0.372549 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_3587_46152"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_3587_46152"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_3587_46152"
        x1="106.308"
        y1="68.4863"
        x2="121.548"
        y2="110.593"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E3EDFA" />
        <stop offset="1" stopColor="#A1C4ED" stopOpacity="0.57" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_3587_46152"
        x1="115.636"
        y1="15.7148"
        x2="129.182"
        y2="53.1392"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E3EDFA" />
        <stop offset="1" stopColor="#A1C4ED" stopOpacity="0.57" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_3587_46152"
        x1="115.636"
        y1="124.836"
        x2="129.182"
        y2="162.26"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E3EDFA" />
        <stop offset="1" stopColor="#A1C4ED" stopOpacity="0.57" />
      </linearGradient>
      <clipPath id="clip0_3587_46152">
        <rect
          width="315.347"
          height="267.093"
          fill="white"
          transform="translate(0.32666 24.9062)"
        />
      </clipPath>
    </defs>
  </svg>
);
