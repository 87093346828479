import { createContext, FC, useContext, useState } from 'react';

interface IPageContext {
  title: string;
  selectedInstanceId: string;
}

interface IPageActionsContext {
  setTitle: (title: string) => void;
  setSelectedInstanceId: (instance: string) => void;
}

const PageContext = createContext<IPageContext>({
  title: '',
  selectedInstanceId: '',
});

const PageActionsContext = createContext<IPageActionsContext>({
  setTitle: () => null,
  setSelectedInstanceId: () => null,
});

export const PageProvider: FC = ({ children }) => {
  const [title, setTitle] = useState('');
  const [selectedInstanceId, setSelectedInstanceId] = useState('');

  return (
    <PageContext.Provider value={{ title, selectedInstanceId }}>
      <PageActionsContext.Provider value={{ setTitle, setSelectedInstanceId }}>
        {children}
      </PageActionsContext.Provider>
    </PageContext.Provider>
  );
};

export const usePage = (): [IPageContext, IPageActionsContext] => {
  const page = useContext(PageContext);
  const pageActions = useContext(PageActionsContext);

  return [page, pageActions];
};
