import { FC, Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';

import { FullPageSpinner } from '@futurefit-ai/ffui';

import i18n from './i18n';
import { AuthProvider } from './AuthContext';
import { GraphProvider } from './GraphContext';
import { FeatureProvider } from './FeatureContext';
import { PageProvider } from './PageContext';

const AppProviders: FC = ({ children }) => {
  return (
    //Wrapping providers in Suspense as some hooks like useTranslation in GraphProvider are not immediately available. Provides fallback till hook ready.
    <Suspense fallback={<FullPageSpinner />}>
      <Router>
        <I18nextProvider i18n={i18n}>
          <AuthProvider>
            <GraphProvider>
              <FeatureProvider>
                <PageProvider>{children}</PageProvider>
              </FeatureProvider>
            </GraphProvider>
          </AuthProvider>
        </I18nextProvider>
      </Router>
    </Suspense>
  );
};

export { AppProviders };
