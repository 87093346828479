/**
 * Returns an array of (non-object) values from a nested object
 * @param obj
 * @returns array
 *
 * @example
 * const nestedObject = {
 *  foo: {
 *      bar: '123',
 *  },
 *  xyz: 'abc',
 * }
 *
 * leafValue(nestedObject) // ['123', 'abc']
 *
 */
export const leafValue = (obj: object | any) => {
  if (typeof obj !== 'object') {
    return obj;
  }
  const list: any = [];
  for (const prop in obj) {
    list.push(leafValue(obj[prop]));
  }
  return list.flat();
};
