import { t } from 'i18next';

import { INTERNATIONAL_LOCALES } from '../context/i18n';

/**
 * An enum containing all the currencies supported
 * by our platform.
 */
export enum SupportedCurrency {
  USD = 'USD',
  CAD = 'CAD',
  EUR = 'EUR',
}

/**
 * An object containing all the metadata for all of our supported currencies.
 * Uses the ISO 4217 standard as a basis for all the information here
 * except symbol as that is not defined.
 */
const CURRENCY_CODES: {
  [key in SupportedCurrency]: {
    getCountryName: () => string;
    currencyNumber: number;
    getCurrencyName: () => string;
    currencySymbol: string;
  };
} = {
  [SupportedCurrency.USD]: {
    getCountryName: () => t('global.countries.us', 'United States of America'),
    currencyNumber: 840,
    getCurrencyName: () => t('global.currencies.usd', 'US Dollar'),
    currencySymbol: '$',
  },
  [SupportedCurrency.CAD]: {
    getCountryName: () => t('global.countries.canada', 'Canada'),
    currencyNumber: 124,
    getCurrencyName: () => t('global.currencies.cad', 'Canadian Dollar'),
    currencySymbol: 'C$',
  },
  [SupportedCurrency.EUR]: {
    getCountryName: () => t('global.countries.europe', 'European Union'),
    currencyNumber: 978,
    getCurrencyName: () => t('global.currencies.euro', 'European Euro'),
    currencySymbol: '€',
  },
};

/**
 * Represents all the metadata associated with
 * a particular currency.
 */
export type Currency = {
  countryName: string;
  currencyCode: SupportedCurrency;
  currencyName: string;
  currencyNumber: number;
  currencySymbol: string;
};

/**
 * An interface which allows for conveiently accessing
 * supported currencies. This is the recommended way to access
 * the currency information.
 *
 * Note: MUST be used in a component that's wrapped by the
 * `I18nProvider`.
 */
export class CurrencyCode {
  /**
   * Returns all the supported currencies.
   */
  static getAll(): Currency[] {
    return (Object.keys(SupportedCurrency) as SupportedCurrency[]).map(
      (currencyCode) => ({
        countryName: CURRENCY_CODES[currencyCode].getCountryName(),
        currencyCode: currencyCode,
        currencyName: CURRENCY_CODES[currencyCode].getCurrencyName(),
        currencyNumber: CURRENCY_CODES[currencyCode].currencyNumber,
        currencySymbol: CURRENCY_CODES[currencyCode].currencySymbol,
      })
    );
  }

  /**
   * Get all the metadata for the given `currencyCode`.
   */
  static get(currencyCode: SupportedCurrency): Currency {
    const { currencyNumber, currencySymbol, getCountryName, getCurrencyName } =
      CURRENCY_CODES[currencyCode];

    return {
      countryName: getCountryName(),
      currencyCode,
      currencyName: getCurrencyName(),
      currencyNumber,
      currencySymbol,
    };
  }
}

/**
 * Formats the given input as a salary that
 * respects the user's locale.
 *
 * @param salary - The salary to format.
 * @param lng - The user's locale.
 * @param currency - The currency to use.
 *
 * @example If user’s platform language is fr-CA/es-ES/pt-PT, and the job’s salary range is in USD. They should see 123 456,89 $.
 * @example If user’s platform language is en-US, and the job’s salary range is in EUR. They should see euro symbol but in en-US formatting, i.e. €123,456.89
 *
 * @remarks By default, currency formatted in `es-ES` locale will be separated by a period (rather than spaces), e.g. 123.456,89 (es-ES) rather than 123 456,89 (fr-CA/ pt-PT). Since we want es-ES & pt-PT to follow fr-CA, we will manually change `es-ES` to `fr-CA`.
 */
export const formatSalary = (
  salary: number,
  {
    currency,
    lng,
  }: {
    lng: string;
    currency?: string;
  }
) => {
  const locale = lng === 'es-ES' ? 'fr-CA' : lng;
  const currencyCode = currency ?? INTERNATIONAL_LOCALES[lng].currency;

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode,
    currencyDisplay: 'narrowSymbol',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    useGrouping: true,
  }).format(salary);
};
