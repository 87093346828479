import * as React from 'react';
import { useSearchParams } from 'react-router-dom';

import { FullPageSpinner } from '@futurefit-ai/ffui';

import { useAuth } from '../context/AuthContext';
import { SIGN_UP_REDIRECT_URL_PARAM } from '../components/lib';
import { isValidHttpUrl } from '../utils/URL';

const AuthenticatedApp = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true */ '../components/AuthenticatedApp.component'
    )
);
const UnauthenticatedApp = React.lazy(
  () => import('../components/UnauthenticatedApp.component')
);

function AppRoutes() {
  const { user, isLoading } = useAuth();
  const [searchParams] = useSearchParams();
  const signUpRedirectUrl = searchParams.get(SIGN_UP_REDIRECT_URL_PARAM);

  const isUserAuthenticated = Boolean(user.token);

  if (isLoading) {
    return <FullPageSpinner />;
  }

  if (isUserAuthenticated && isValidHttpUrl(signUpRedirectUrl)) {
    // if the user is authenticated and there is a valid redirect URL, redirect the user to the URL
    // this is used to redirect the user after they sign in, sign up, or comes back to the link when they are already signed in
    window.location.replace(signUpRedirectUrl as string);
  }

  return (
    <React.Suspense fallback={<FullPageSpinner />}>
      {isUserAuthenticated ? <AuthenticatedApp /> : <UnauthenticatedApp />}
    </React.Suspense>
  );
}

export { AppRoutes };
