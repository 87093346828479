import { Cache } from '@urql/exchange-graphcache';

import {
  SearchJobsV2Document,
  SearchJobsV2Query,
  SearchJobsV2QueryVariables,
} from '../generated-mesh/graphql';

// we change the request policy to cache-first (default) and update searchJobs query data to cache
// the changes immediately when the mongodb atlas search index rebuilding for the job data.
export const updateSearchJobsCache = (
  cache: Cache,
  callback: (data: any, field?: any) => any
) => {
  const searchJobsFields = cache
    .inspectFields({ __typename: 'Query' })
    .filter((field) => field.fieldName === 'searchJobsV2');

  searchJobsFields.forEach((field) => {
    const variables = field.arguments as SearchJobsV2QueryVariables;
    const { search, filters_array = [] } = variables.searchInput;
    // invalidate cache for pagination or querying with searchKey or location
    const hasLocation = filters_array.some((filter) => filter.location);
    if (search || hasLocation) {
      cache.invalidate('Query', field.fieldKey);
    }
    cache.updateQuery<SearchJobsV2Query, SearchJobsV2QueryVariables>(
      {
        query: SearchJobsV2Document,
        variables,
      },
      (data: SearchJobsV2Query | null) => {
        if (!data?.searchJobsV2?.jobs) {
          return data;
        }
        return callback(data, field);
      }
    );
  });
};
