import * as React from 'react';
import { render } from 'react-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { createBrowserHistory } from 'history';

import { AppProviders } from './context';
import { AppRoutes } from './routes';

import './styles/global.css';

// With this flag we could deploy these changes without breaking anything,
// as Sentry will not be enabled by default.
const enableSentry =
  !!import.meta.env.VITE_ENV && // env IS set
  !!import.meta.env.VITE_SENTRY_DSN && // Sentry DSN IS set
  !window.location.hostname.includes('localhost'); // not localhost

Sentry.init({
  environment: import.meta.env.VITE_ENV,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  release: import.meta.env.VITE_GIT_SHA1, // generated on build by webpack

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production.
  //
  // As a baseline I'm leaving this at 100%. This can suck up
  // a ton of our ingest quota, so we definitely want to adjust this;
  // however in my experience it is a magic number until you actually
  // know what quantity of ingest you are dealing with.
  // 50% feels just about right for halving our ingest and giving us
  // time to inspect and consider if it should be higher or lower.
  tracesSampleRate: 1.0,
  enabled: enableSentry,
});

function Root() {
  return (
    <React.StrictMode>
      <AppProviders>
        <AppRoutes />
      </AppProviders>
    </React.StrictMode>
  );
}

const history = createBrowserHistory();
const rootElement = document.getElementById('root');

// This is required for client side routing in S3
if (rootElement) {
  // eslint-disable-next-line no-restricted-globals
  const path = (/#!(\/.*)$/.exec(location.hash) || [])[1];
  if (path) {
    history.replace(path);
  }
  render(<Root />, rootElement);
}

export const GRAPHQL_MESH_ENABLED =
  import.meta.env.VITE_GRAPHQL_MESH_ENABLED === 'true';
