import { FC } from 'react';

type SVGSearchIconProps = {
  className?: string;
  size?: number;
};

export const SvgSearchIcon: FC<SVGSearchIconProps> = ({ size }) => {
  return (
    <svg
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
    >
      <path
        d="M3.38 11.359a6.084 6.084 0 1 0 11.198-4.76A6.084 6.084 0 0 0 3.38 11.36v0ZM13.28 13.28 18 18"
        stroke="currentColor"
        strokeWidth="1.14"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </svg>
  );
};
