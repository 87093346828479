import i18n from 'i18next';

import {
  GetCurrentUserTenantQuery,
  GetJobQuery,
} from '../../generated-mesh/graphql';
import { GetUserProfileQuery } from '../../graphql-mesh/migration-union-types';
import { CandidateProfile } from './FindTalentTemplate';

type GetRecruiterByUserUuidQuery =
  import('../../graphql-mesh/migration-union-types').GetRecruiterByUserUuidQuery;

export const DEFAULT_JOBS_LIMIT = 25;
/**
 * The default number of candidates ids to fetch from the
 * recommender in one batch.
 */
export const DEFAULT_CANDIDATE_BATCH_SIZE = 250;
/**
 * The default number of candidates to display on the
 * page at a time.
 */
export const DEFAULT_CANDIDATES_LIMIT = 25;
export const DEFAULT_SAVED_ONLY_STATE = false;
export const DEFAULT_TRAINABLE_STATE = false;
export const MAX_SKILLS_DISPLAY = 10;
export const SEARCH_JOBS_INPUT_STYLES =
  'p-4 pl-11 outline-0 !border-[1px] !border-solid !border-transparent text-black w-full font-primary disabled:cursor-not-allowed font-light text-xs disabled:bg-dawn-30 rounded lg:rounded-l lg:rounded-r-none focus:rounded-r focus:shadow-1 focus:border-none focus:outline-0 focus:ring-offset-0 focus:ring-1 focus:ring-purple';

type getRecruiterOutreachEmailSubjectProps = {
  implementationType: 'business_services' | 'recruiter';
  loggedInUserTenant: GetCurrentUserTenantQuery | undefined;
  recruiterUserProfile?: GetRecruiterByUserUuidQuery['getRecruiterByUserUuid'];
  selectedJobData: GetJobQuery | undefined;
  talentProfile: CandidateProfile | undefined;
};

/**
 * Get email subject for recruiter outreach email
 * @param implementationType - is this component in the business services or recruiter implementation
 * @param recruiterUserProfile
 * @param selectedJobData - if a current job is in scope when contacting talent user
 * @param talentFirstName
 * @returns
 */
export const getRecruiterOutreachEmailSubject = ({
  implementationType,
  loggedInUserTenant,
  recruiterUserProfile,
  selectedJobData,
  talentProfile,
}: getRecruiterOutreachEmailSubjectProps) => {
  const recruiterEmailSubject = i18n.t(
    'recruiters.outreach_email.subject',
    '{{talentFirstName}}, {{employerName}} is Hiring',
    {
      talentFirstName: talentProfile?.first_name,
      employerName: recruiterUserProfile?.employer?.name,
    }
  );

  const businessServicesJobSelectedEmailSubject = i18n.t(
    'business_services.outreach_email.subject_job_selected',
    '{{talentFirstName}}, {{employerName}} is Hiring',
    {
      talentFirstName: talentProfile?.first_name,
      employerName: selectedJobData?.getJob?.company,
    }
  );

  const businessServicesNoJobSelectedEmailSubject = i18n.t(
    'business_services.outreach_email.subject_no_job_selected',
    '{{tenantName}} Outreach',
    { tenantName: loggedInUserTenant?.getCurrentUserTenant?.name }
  );

  if (implementationType === 'recruiter') {
    return recruiterEmailSubject;
  } else if (implementationType === 'business_services' && selectedJobData) {
    return businessServicesJobSelectedEmailSubject;
  } else if (implementationType === 'business_services' && !selectedJobData) {
    return businessServicesNoJobSelectedEmailSubject;
  } else {
    return 'Something went wrong.';
  }
};

type getRecruiterOutreachEmailBodyProps = {
  implementationType: 'business_services' | 'recruiter';
  loggedInUserProfile: GetUserProfileQuery | undefined;
  loggedInUserTenant: GetCurrentUserTenantQuery | undefined;
  recruiterUserProfile?: GetRecruiterByUserUuidQuery['getRecruiterByUserUuid'];
  selectedJobData: GetJobQuery | undefined;
  talentProfile: CandidateProfile | undefined;
};

/**
 * Get email body for recruiter outreach email
 * @param implementationType - is this component in the business services or recruiter implementation
 * @param recruiterUserProfile // partners-service
 * @param selectedJobData - if a current job is in scope when contacting talent user
 * @param talentFirstName
 * @returns
 */
export const getRecruiterOutreachEmailBody = ({
  implementationType,
  loggedInUserProfile,
  loggedInUserTenant,
  recruiterUserProfile,
  selectedJobData,
  talentProfile,
}: getRecruiterOutreachEmailBodyProps) => {
  const recruiterEmailBody = i18n.t(
    'recruiters.outreach_email.body',
    "Hi {{talentFirstName}}, %0D%0D My name is {{recruiterFirstName}}, and I'm a recruiter for {{employerName}}. %0D%0D We're hiring for several positions! Let me know if you're interested in learning more. %0D%0D Thanks, %0D{{recruiterFirstName}}",
    {
      employerName: recruiterUserProfile?.employer?.name,
      recruiterFirstName: recruiterUserProfile?.user?.first_name,
      talentFirstName: talentProfile?.first_name,
    }
  );

  const businessServicesJobSelectedEmailBody = i18n.t(
    'business_services.outreach_email.body_job_selected',
    'Hi {{talentFirstName}}, %0D%0D My name is {{loggedInUserFirstName}} from {{tenantName}}, I came across your profile and I think you would be a great fit for the {{roleName}} role at {{companyName}}. %0D%0D Let me know if you are interested in learning more. %0D%0D Thanks, %0D {{loggedInUserFirstName}}',
    {
      companyName: selectedJobData?.getJob?.company,
      loggedInUserFirstName: loggedInUserProfile?.getUserProfile?.first_name,
      roleName: selectedJobData?.getJob?.job_title,
      talentFirstName: talentProfile?.first_name,
      tenantName: loggedInUserTenant?.getCurrentUserTenant?.name,
    }
  );

  const businessServicesNoJobSelectedEmailBody = i18n.t(
    'business_services.outreach_email.body_no_job_selected',
    'Hi {{talentFirstName}}, %0D%0D My name is {{loggedInUserFirstName}} from {{tenantName}}, I came across your profile and I think you would be a great fit for several positions posted by our employer partners. %0D Let me know if you are interested in learning more. %0D%0D Thanks, %0D {{loggedInUserFirstName}}',
    {
      loggedInUserFirstName: loggedInUserProfile?.getUserProfile?.first_name,
      talentFirstName: talentProfile?.first_name,
      tenantName: loggedInUserTenant?.getCurrentUserTenant?.name,
    }
  );

  if (implementationType === 'recruiter') {
    return recruiterEmailBody;
  } else if (implementationType === 'business_services' && selectedJobData) {
    return businessServicesJobSelectedEmailBody;
  } else if (implementationType === 'business_services' && !selectedJobData) {
    return businessServicesNoJobSelectedEmailBody;
  } else {
    return 'Something went wrong.';
  }
};

type buildEmailProps = {
  implementationType: 'business_services' | 'recruiter';
  loggedInUserProfile: GetUserProfileQuery | undefined;
  loggedInUserTenant: GetCurrentUserTenantQuery | undefined;
  recruiterUserProfile?: GetRecruiterByUserUuidQuery['getRecruiterByUserUuid'];
  selectedJobData: GetJobQuery | undefined;
  talentProfile: CandidateProfile | undefined;
};

export const buildEmail = ({
  implementationType,
  loggedInUserProfile,
  loggedInUserTenant,
  recruiterUserProfile,
  selectedJobData,
  talentProfile,
}: buildEmailProps) => {
  const emailSubject = getRecruiterOutreachEmailSubject({
    implementationType,
    loggedInUserTenant,
    recruiterUserProfile,
    selectedJobData,
    talentProfile,
  });

  const emailBody = getRecruiterOutreachEmailBody({
    implementationType,
    loggedInUserProfile,
    loggedInUserTenant,
    recruiterUserProfile,
    selectedJobData,
    talentProfile,
  });

  return { emailSubject, emailBody };
};
