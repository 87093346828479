import type { IconSizes } from '@futurefit-ai/ffui/dist/components/atoms/Icons/IconBase.component';

import {
  IconBriefcase as IconEmployed,
  IconBook as IconEducation,
  IconReferral,
  IconUser as IconDisengaged,
  IconCheck as IconOther,
} from '@futurefit-ai/ffui';

import i18n from '../context/i18n';

export enum OutcomeType {
  EMPLOYED = 'employed',
  EDUCATION = 'education',
  REFERRAL = 'referral',
  DISENGAGED = 'disengaged',
  OTHER = 'other',
}

export enum EducationType {
  HIGH_SCHOOL = 'high_school',
  POLYTECHNIC = 'polytechnic_diploma',
  BACHELOR_DEGREE = 'bachelor_degree',
  MASTERS_DEGREE = 'masters_degree',
  DOCTORATE = 'doctorate',
  BOOTCAMP = 'bootcamp',
  CONTINUING_EDUCATION = 'continuing_education',
  ONLINE_CLASSES = 'online_classes',
  APPRENTICESHIP = 'apprenticeship',
  ASSOCIATE_DEGREE = 'associate_degree',
  OTHER = 'other',
}

export enum OutcomeEducationType {
  HIGH_SCHOOL_DIPLOMA = 'high_school_diploma',
  COLLEGE = 'college',
  BACHELOR = 'bachelor',
  MASTER = 'master',
  DOCTORATE = 'doctorate',
  BOOTCAMP = 'bootcamp',
  CONTINUING_EDUCATION = 'continuing_education',
  ONLINE_CLASSES = 'online_classes',
  APPRENTICESHIP = 'apprenticeship',
  OTHER = 'other',
}

/**
 * Returns the translated label for the given `outcome`.
 */
export const getOutcomeTypeLabel = (outcome: OutcomeType) => {
  switch (outcome) {
    case OutcomeType.DISENGAGED:
      return i18n.t('global.outcomes.disengaged', 'Disengaged');

    case OutcomeType.EDUCATION:
      return i18n.t('global.outcomes.education', 'Education');

    case OutcomeType.EMPLOYED:
      return i18n.t('global.outcomes.employed', 'Employed');

    case OutcomeType.REFERRAL:
      return i18n.t('global.outcomes.referral', 'Referral');

    default:
    case OutcomeType.OTHER:
      return i18n.t('global.outcomes.other', 'Other');
  }
};

export const OutcomeIcon = ({
  outcome,
  className,
  size = 20,
}: {
  outcome: OutcomeType;
  className?: string;
  size?: IconSizes;
}) => {
  switch (outcome) {
    case OutcomeType.DISENGAGED:
      return <IconDisengaged size={size} className={className} />;

    case OutcomeType.EDUCATION:
      return <IconEducation size={size} className={className} />;

    case OutcomeType.EMPLOYED:
      return <IconEmployed size={size} className={className} />;

    case OutcomeType.REFERRAL:
      return <IconReferral size={size} className={className} />;

    default:
    case OutcomeType.OTHER:
      return <IconOther size={size} className={className} />;
  }
};

/**
 * Returns the translated label for the given `educationType`.
 */
export const getEducationTypeLabel = (
  educationType: OutcomeEducationType | EducationType
) => {
  switch (educationType) {
    case OutcomeEducationType.HIGH_SCHOOL_DIPLOMA:
    case EducationType.HIGH_SCHOOL:
      return i18n.t(
        'global.education_types.high_school_diploma',
        'High School Diploma (or equivalent)'
      );

    case OutcomeEducationType.COLLEGE:
    case EducationType.POLYTECHNIC:
      return i18n.t(
        'global.education_types.college',
        'College or Polytechnic Diploma (or equivalent)'
      );

    case OutcomeEducationType.BACHELOR:
    case EducationType.BACHELOR_DEGREE:
      return i18n.t(
        'global.education_types.bachelor',
        "Bachelor's Degree (or equivalent)"
      );

    case OutcomeEducationType.MASTER:
    case EducationType.MASTERS_DEGREE:
      return i18n.t(
        'global.education_types.master',
        "Master's Degree (or equivalent)"
      );

    case EducationType.DOCTORATE:
      return i18n.t(
        'global.education_types.doctorate',
        'Doctorate Degree (or equivalent)'
      );

    case EducationType.BOOTCAMP:
      return i18n.t(
        'global.education_types.bootcamp',
        'Bootcamp (ex. Coding Bootcamp)'
      );

    case EducationType.CONTINUING_EDUCATION:
      return i18n.t(
        'global.education_types.continuing_education',
        'Continuing Education (ex. Evening Classes)'
      );

    case EducationType.ONLINE_CLASSES:
      return i18n.t(
        'global.education_types.online_classes',
        'Online Classes (ex. Free Online Courses)'
      );

    case EducationType.APPRENTICESHIP:
      return i18n.t(
        'global.education_types.apprenticeship',
        'Apprenticeship or Co-op (or equivalent)'
      );

    case EducationType.ASSOCIATE_DEGREE:
      return i18n.t(
        'global.education_types.associate_degree',
        "Associate's degree"
      );

    case EducationType.OTHER:
      return i18n.t('global.education_types.other', 'Other');
  }
};
